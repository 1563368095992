import { Stack } from "@mui/material";
import ScrollDown from "components/ScrollDown";
import { ReactElement } from "react";

import styles from "./Page.module.scss";

interface Props {
  children: ReactElement;
  withScrollDown?: boolean;
}

const Page = (props: Props) => {
  const { children, withScrollDown } = props;

  return (
    <Stack minHeight="100vh" justifyContent="center" position="relative">
      {children}
      {withScrollDown && <ScrollDown className={styles.scrollDown} />}
    </Stack>
  );
};

export default Page;
