import { Container, Stack } from "@mui/material";
import MagicWall from "components/MagicWall";
import Work from "components/Work/Work";

const MagicWallSection = () => {
  return (
    <Container sx={{ mt: 8, mb: 8 }}>
      <Stack>
        <Work />
        <MagicWall />
      </Stack>
    </Container>
  );
};

export default MagicWallSection;
