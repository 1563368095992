import { v4 as uuidv4 } from "uuid";

export interface MagicWallItem {
  id: string;
  src: string;
  alt: string;
  backgroundColor: string;
}

export const ITEMS: MagicWallItem[] = [
  {
    id: uuidv4(),
    src: "https://res.cloudinary.com/pokpok/image/upload/v1679167053/logos_-_air_france_cntsye.svg",
    alt: "Air France",
    backgroundColor: "#ffffff",
  },
  {
    id: uuidv4(),
    src: "https://res.cloudinary.com/pokpok/image/upload/v1679167119/logo_-_fastory_full_light_hziojp.svg",
    alt: "Fastory",
    backgroundColor: "#081938",
  },
  {
    id: uuidv4(),
    src: "https://res.cloudinary.com/pokpok/image/upload/v1679167053/logo_-_ffyn_eqjkwi.png",
    alt: "Ffyn",
    backgroundColor: "#ffffff",
  },
  {
    id: uuidv4(),
    src: "https://res.cloudinary.com/pokpok/image/upload/v1683056962/draft-dark-theme-logo_ry2nnr.png",
    alt: "Draw to Trello",
    backgroundColor: "#000000",
  },
  {
    id: uuidv4(),
    src: "https://res.cloudinary.com/pokpok/image/upload/v1683056962/draft-white-theme-logo_vhegzy.png",
    alt: "Aqua Care",
    backgroundColor: "#ffffff",
  },
  {
    id: uuidv4(),
    src: "https://res.cloudinary.com/pokpok/image/upload/v1682632001/2023-04-27-logo-draft_ylog5e.png",
    alt: "Snooze",
    backgroundColor: "#ffffff",
  },
  {
    id: uuidv4(),
    src: "https://res.cloudinary.com/pokpok/image/upload/v1682632001/2023-04-27-logo-draft_ylog5e.png",
    alt: "Draw to Trello",
    backgroundColor: "#ffffff",
  },
];
