export const background = {
  primary: "#000000",
  secondary: "rgb(24, 24, 24)",
  tertiary: "#1d1d1d",
};

export const divider = {
  color: {
    primary: "#282828",
  },
};

export const font = {
  color: {
    primary: {
      rest: "#ffffff",
    },
    secondary: {
      rest: "#909096",
      hover: "#08FDD8",
    },
  },
};

export const transition = "color 250ms ease-in-out";
