import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Container, Stack, TextField, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import axios from "axios";
import { useState } from "react";
import { useLocalStorage } from "services/hooks.service";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useSnackbar } from "notistack";

const schema = yup.object({
  from: yup.string().required(),
  subject: yup.string().required(),
  bodyText: yup.string().required(),
  applicationName: yup.string().required(),
});

interface CreateContactInboundDto {
  fromName: string;
  fromEmail: string;
  subject: string;
  bodyText: string;
  applicationName: "portfolio";
}

interface Props {}

const Contact = (props: Props) => {
  const {} = props;

  const { getValues, handleSubmit, register } =
    useForm<CreateContactInboundDto>({
      resolver: yupResolver(schema),
    });
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  const onSubmit = async (_: any) => {
    const createContactInboundDto = getValues();

    try {
      setIsLoading(true);
      await axios.post(`${process.env.REACT_APP_API_URL_PREFIX}/contacts`, {
        fromEmail: createContactInboundDto.fromEmail,
        fromName: createContactInboundDto.fromName,
        subject: createContactInboundDto.subject,
        bodyText: createContactInboundDto.bodyText,
        applicationName: "portfolio",
      });
      setHasContactedMe(true);
      enqueueSnackbar(intl.formatMessage({ id: "contact.submit.success" })),
        { variant: "success" };
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [hasContactedMe, setHasContactedMe] = useLocalStorage<boolean>(
    "hasContactedMe",
    false
  );

  return (
    <Container>
      <Stack justifyContent="center" minHeight={"100vh"}>
        <Typography variant="h2">
          <FormattedMessage id="contact.title" />
        </Typography>
        <Typography mt={4} variant="body2">
          <FormattedMessage id="contact.description" />
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack gap={1}>
            <input
              type="hidden"
              {...register("applicationName", { required: true })}
              value="portfolio"
            />
            <Stack direction={{ xs: "column", md: "row" }} mt={4} gap={1}>
              <TextField
                label="Name"
                variant="filled"
                sx={{ flex: 1 }}
                {...register("fromName", { required: true })}
              />
              <TextField
                label="Email"
                variant="filled"
                sx={{ flex: 1 }}
                {...register("fromEmail", { required: true })}
              />
            </Stack>
            <TextField
              label="Subject"
              variant="filled"
              {...register("subject", { required: true })}
            />
            <TextField
              label="Message"
              variant="filled"
              multiline
              rows={4}
              {...register("bodyText", { required: true })}
            />
            <Stack direction="row" justifyContent="flex-end" mt={2} mb={8}>
              <Button
                variant="outlined"
                onClick={onSubmit}
                disabled={isLoading || hasContactedMe}
                type="submit"
              >
                <FormattedMessage id="contact.form.submit" />
              </Button>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </Container>
  );
};

export default Contact;
