import { Button, Container, Stack } from "@mui/material";
import ArticleCard from "components/ArticleCard";
import { FormattedMessage } from "react-intl";
import { useGetArticles } from "generated/hook";

interface Props {}

const Blog = (props: Props) => {
  const {} = props;
  const { data, isLoading } = useGetArticles();

  if (!data || isLoading) {
    // @debt loader "Add a loading component in a container there"
    return null;
  }

  return (
    <Container>
      <Stack mt={20}>
        <Stack direction="row" flexWrap="wrap" gap={5}>
          {data.map((article) => (
            <ArticleCard
              key={article.pageId}
              category={article.category}
              id={article.pageId}
              title={article.title}
              preview={article.preview}
            />
          ))}
        </Stack>
        <Stack direction="row" flex={1} justifyContent="center" py={10}>
          <Button variant="outlined">
            <FormattedMessage id="blog.seeAll" />
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};

export default Blog;
