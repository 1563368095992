import { createTheme } from "@mui/material";

export const cn = (
  ...classNames: Array<string | boolean | undefined | null>
) => {
  let concatenatedClassNames = "";

  classNames.forEach((className) => {
    if (!!className) {
      concatenatedClassNames = concatenatedClassNames + className + " ";
    }
  });

  return concatenatedClassNames;
};

export const COLOR_PALETTE = {
  background: {
    primary: "#252934",
    secondary: "#1B242F",
    third: "#0CC2C9",
  },
  selected: "#E21B6E",
  text: {
    primary: "#FFFFFF",
    secondary: "##444649",
    third: "#626262",
  },
};

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#8D8D8D",
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {},
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: "#1d1d1d",
          borderRadius: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "0",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "rgba(255, 255, 255, 0.5)",
          transition: "all 250ms ease-in-out",
        },
        root: {
          color: "white",
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "white",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#8d8d8d",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            backgroundColor: "#2B2B2B",
          },
        },
        input: {
          backgroundColor: "#2B2B2B",
          color: "#8d8d8d",
          "&$focused": {
            backgroundColor: "#2B2B2B",
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#2B2B2B",
          "&:hover": {
            backgroundColor: "#2B2B2B",
          },
          "&.Mui-focused": {
            backgroundColor: "#2B2B2B",
          },
        },
      },
    },
  },
});

muiTheme.typography.h1 = {
  color: "#08fdd8",

  [muiTheme.breakpoints.up("md")]: {
    fontSize: "72px",
  },
  [muiTheme.breakpoints.down("md")]: {
    fontSize: "48px",
  },
};
muiTheme.typography.h2 = {
  color: "#08fdd8",
  [muiTheme.breakpoints.up("md")]: {
    fontSize: "72px",
    fontWeight: 600,
  },
  [muiTheme.breakpoints.down("md")]: {
    fontSize: "48px",
  },
};
muiTheme.typography.h5 = {
  color: "#ffffff",
  fontSize: "27px",
  fontWeight: 400,
};
muiTheme.typography.body2 = {
  color: "#ffffff",
  [muiTheme.breakpoints.up("md")]: {
    fontSize: "16px",
  },
  [muiTheme.breakpoints.down("md")]: {
    fontSize: "14px",
  },
};

muiTheme.typography.body1 = {
  color: "#a7a7a7",
  [muiTheme.breakpoints.up("md")]: {
    fontSize: "16px",
  },
  [muiTheme.breakpoints.down("md")]: {
    fontSize: "14px",
  },
};
muiTheme.typography.h6 = {
  fontSize: "13px",
  textTransform: "uppercase",
  letterSpacing: "3px",
};
