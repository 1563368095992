import { Container, Stack } from "@mui/material";

import { ReactElement } from "react";

interface Props {
  LeftChildren?: ReactElement;
  RightChildren?: ReactElement;
}

const HorizontalSplitSection = (props: Props) => {
  const { LeftChildren, RightChildren } = props;

  return (
    <Container sx={{ mt: 8, mb: 8 }}>
      <Stack direction={{ xs: "column", sm: "row" }} gap={8}>
        <Stack flex={1}>{LeftChildren}</Stack>
        <Stack flex={1}>{RightChildren}</Stack>
      </Stack>
    </Container>
  );
};

export default HorizontalSplitSection;
