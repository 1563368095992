export const ROUTES = {
  HOME: () => "/",
  SKILLS: () => "/skills",
  ABOUT: () => "/about",
  WORKS: () => "/works",
  WORK: (workId: string = ":workId") => `/work/${workId}`,
  BLOG: () => `/blog`,
  ARTICLE: (articleId: string = ":blogId") => `/blog/${articleId}`,
  CONTACT: () => "/contact",
};
