import { Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

interface Props {}

const ErrorPage = (props: Props) => {
  const {} = props;
  const error = useRouteError();

  if (!isRouteErrorResponse(error)) {
    return (
      <Stack>
        <Typography variant="h1">
          <FormattedMessage id="errorPage.title" />
        </Typography>
        <i>Unexpected error</i>
      </Stack>
    );
  }

  return (
    <Stack>
      <Typography variant="h2">
        <FormattedMessage id="errorPage.title" />
      </Typography>
      <Typography variant="body2">{error.data}</Typography>
    </Stack>
  );
};

export default ErrorPage;
