import { Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

interface Props {}

const Work = (props: Props) => {
  const {} = props;

  return (
    <Stack>
      <Typography variant="h2">
        <FormattedMessage id="global.portfolio.title" />
      </Typography>
      <Typography variant="body1" sx={{ mt: 4 }}>
        <FormattedMessage id="global.portfolio.description" />
      </Typography>
    </Stack>
  );
};

export default Work;
