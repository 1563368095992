import { Button, Container, Stack } from "@mui/material";
import ScrollDown from "components/ScrollDown";
import { ReactElement } from "react";

import styles from "./MainSection.module.scss";
import { FormattedMessage } from "react-intl";

interface Props {
  children: ReactElement;
  withScrollDown?: boolean;
  ButtonProps?: {
    href?: string;
    onClick?: VoidFunction;
    translationId: string;
  };
}

const MainSection = (props: Props) => {
  const { ButtonProps, children, withScrollDown } = props;

  return (
    <Container>
      <Stack position="relative" justifyContent="center">
        <Stack boxSizing="border-box">
          {children}
          {ButtonProps && (
            <div style={{ paddingTop: "50px" }}>
              <Button variant="outlined" href={ButtonProps.href}>
                <FormattedMessage id={ButtonProps.translationId} />
              </Button>
            </div>
          )}
        </Stack>
        {withScrollDown && <ScrollDown className={styles.scrollDown} />}
      </Stack>
    </Container>
  );
};

export default MainSection;
