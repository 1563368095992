import { Stack, Typography } from "@mui/material";
import AboutInContainer from "components/AboutInContainer";
import Blog from "components/Blog";
import Contact from "components/Contact";
import { FormattedMessage } from "react-intl";

import styles from "./Global.module.scss";
import MainSection from "components/MainSection/MainSection";
import HorizontalSplitSection from "components/HorizontalSplitSection";
import MagicWallSection from "components/MagicWallSection/MagicWallSection";
import Page from "components/Page";

interface Props {}

const Global = (props: Props) => {
  const {} = props;

  return (
    <Stack
      className={styles.view}
      height="100vh"
      sx={{ overflowY: "auto" }}
      flex="1"
    >
      <Page withScrollDown>
        <MainSection
          ButtonProps={{
            href: "/contact",
            translationId: "global.contactMe",
          }}
        >
          <>
            <Stack>
              <Typography variant="h1" color="primary">
                <FormattedMessage id="global.hi" />
              </Typography>
              <Typography variant="h1" color="primary">
                <FormattedMessage id="global.imFred" />
              </Typography>

              <Typography variant="body1" color="secondary" sx={{ mt: 4 }}>
                <FormattedMessage id="global.subTitle" />
              </Typography>
            </Stack>
          </>
        </MainSection>
      </Page>

      <MagicWallSection />
      <Page>
        <HorizontalSplitSection
          LeftChildren={<AboutInContainer />}
          RightChildren={
            <div
              className={styles.fade}
              style={{
                height: "100%",
              }}
            >
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                  filter: "saturate(0.5)",
                }}
                src="https://res.cloudinary.com/pokpok/image/upload/v1659887923/PXL_20220607_103620422.PORTRAIT_ttxzdb.jpg"
              />
            </div>
          }
        />
      </Page>
      <Blog />
      <Contact />
    </Stack>
  );
};

export default Global;
