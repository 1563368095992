import { Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

interface Props {}

const About = (props: Props) => {
  const {} = props;

  return (
    <Stack>
      <section>
        <Stack>
          <Typography variant="h2">
            <FormattedMessage id="about.title" />
          </Typography>
        </Stack>
        <Stack direction="row">
          <Stack gap={2} flex={1}>
            <Typography variant="body2" sx={{ mt: 4 }}>
              <FormattedMessage id="about.paragraph.first" />
            </Typography>
            <Typography variant="body2">
              <FormattedMessage id="about.paragraph.second" />
            </Typography>
            <Typography variant="body2">
              <FormattedMessage id="about.paragraph.third" />
            </Typography>
          </Stack>
        </Stack>
      </section>
    </Stack>
  );
};

export default About;
