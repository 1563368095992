import { Button, List, ListItem, Stack } from "@mui/material";
import { background, divider, font, transition } from "services/theme";
import {
  LinkedIn as LinkedInIcon,
  GitHub as GitHubIcon,
} from "@mui/icons-material";

import styles from "./ResponsiveMenu.module.scss";
import { FormattedMessage } from "react-intl";
import { ROUTES } from "services/routes";

const PAGES = [
  { id: "about", translationKey: "menu.item.about", href: ROUTES.ABOUT() },
  {
    id: "mySkills",
    translationKey: "menu.item.mySkills",
    href: ROUTES.SKILLS(),
  },
  { id: "work", translationKey: "menu.item.work", href: ROUTES.WORKS() },
  { id: "blog", translationKey: "menu.item.blog", href: ROUTES.BLOG() },
  {
    id: "contact",
    translationKey: "menu.item.contact",
    href: ROUTES.CONTACT(),
  },
];
const EXTERNAL_LINKS = [
  {
    id: "linkedIn",
    icon: <LinkedInIcon />,
    href: "https://www.linkedin.com/in/frederic-mamath-a23145b2/",
  },
  {
    id: "github",
    icon: <GitHubIcon />,
    href: "https://github.com/frederic-mamath",
  },
];

interface Props {}

const ResponsiveMenu = (props: Props) => {
  const {} = props;

  return (
    <div className={styles.view}>
      <Stack
        sx={{
          position: { xs: "fixed", md: "relative" },
          color: "white",
          height: "100vh",
          width: "100%",
        }}
      >
        <a href="/">
          <Stack
            className={styles.header}
            gap={2}
            sx={{ background: background.primary }}
            py={4}
          >
            <Stack
              sx={{
                height: "60px",
                width: "60px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              F
            </Stack>
            <div>Fred</div>
            <div>Web Developer</div>
          </Stack>
        </a>
        <Stack
          flex="1"
          justifyContent="center"
          sx={{ background: background.secondary }}
        >
          <List
            className={styles.body}
            sx={{
              flex: 1,
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              ".MuiListItem-root": {
                borderBottom: `1px solid ${divider.color.primary}`,
                "&:first-of-type": {
                  borderTop: `1px solid ${divider.color.primary}`,
                },
              },
            }}
          >
            {PAGES.map((page) => (
              <a href={page.href} key={`menu-page-${page.id}`}>
                <ListItem
                  sx={{
                    py: 2,
                    justifyContent: "center",
                    color: font.color.secondary.rest,
                    transition: transition,
                    "&:hover": {
                      color: font.color.secondary.hover,
                    },
                  }}
                >
                  <FormattedMessage id={page.translationKey} />
                </ListItem>
              </a>
            ))}
          </List>
          <Stack
            className={styles.footer}
            direction="row"
            justifyContent="center"
            py={6}
          >
            {EXTERNAL_LINKS.map((externalLink) => (
              <a
                href={externalLink.href}
                key={`menu-mobile-${externalLink.id}`}
                target="_blank"
              >
                <Button
                  sx={{
                    color: font.color.secondary.rest,
                    transition: transition,
                    "&:hover": {
                      color: font.color.secondary.hover,
                    },
                  }}
                >
                  {externalLink.icon}
                </Button>
              </a>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};

export default ResponsiveMenu;
