import { Stack, Typography } from "@mui/material";

interface Props {}

const SkillPage = (props: Props) => {
  const {} = props;

  return (
    <Stack>
      <Typography variant="h2">Skill & experience</Typography>
      <Typography variant="body2">
        With a decade of experience as a full-stack developer, I have had the
        opportunity to work with both startups and large corporations,
        delivering projects tailored to the needs of both consumer users and
        businesses. My diverse background has allowed me to gain valuable
        insights into the unique challenges faced by different types of
        organizations and develop solutions that cater to their specific
        requirements.
      </Typography>
      <Typography variant="body2">
        My passion lies in crafting successful responsive websites that are not
        only fast and easy to use but also adhere to industry best practices. My
        primary focus is on front-end development, where I excel in HTML, CSS,
        and JavaScript. I specialize in building small and medium-sized web
        applications, custom plugins, and features while incorporating engaging
        animations and interactive layouts that elevate the user experience.
      </Typography>
    </Stack>
  );
};

export default SkillPage;
