import { Stack, Typography } from "@mui/material";
import { useGetArticle } from "generated/hook";
import { useParams } from "react-router-dom";

interface Props {}

const BlogItemPage = (props: Props) => {
  const {} = props;
  const { blogId } = useParams();

  if (!blogId) {
    return <Stack>Not found</Stack>;
  }

  const { data } = useGetArticle(blogId);

  if (!data) {
    return <Stack>Not found</Stack>;
  }

  return (
    <Stack>
      {data.map((block) => (
        <Typography variant="body2">{block.content}</Typography>
      ))}
    </Stack>
  );
};

export default BlogItemPage;
