/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * ktan-stack-back
 * OpenAPI spec version: 1.0.0
 */
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  GetArticlesOutboundDto,
  CreateArticle200,
  BadRequestResponse,
  CreateArticleInboundDto,
  GetArticleOutboundDto,
  CreateContactInboundDto,
  UserOutboundDto,
  GetWorksOutboundDto
} from './model'
import { customInstance } from '../services/network';



export const getArticles = (
    
 signal?: AbortSignal
) => {
      return customInstance<GetArticlesOutboundDto[]>(
      {url: `/articles`, method: 'get', signal
    },
      );
    }
  

export const getGetArticlesQueryKey = () => [`/articles`] as const;
  

    
export const getGetArticlesQueryOptions = <TData = Awaited<ReturnType<typeof getArticles>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getArticles>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<typeof getArticles>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetArticlesQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getArticles>>> = ({ signal }) => getArticles(signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetArticlesQueryResult = NonNullable<Awaited<ReturnType<typeof getArticles>>>
export type GetArticlesQueryError = unknown

export const useGetArticles = <TData = Awaited<ReturnType<typeof getArticles>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getArticles>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetArticlesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const createArticle = (
    createArticleInboundDto: CreateArticleInboundDto,
 ) => {
      return customInstance<CreateArticle200>(
      {url: `/articles`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createArticleInboundDto
    },
      );
    }
  


export const getCreateArticleMutationOptions = <TError = BadRequestResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createArticle>>, TError,{data: CreateArticleInboundDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createArticle>>, TError,{data: CreateArticleInboundDto}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createArticle>>, {data: CreateArticleInboundDto}> = (props) => {
          const {data} = props ?? {};

          return  createArticle(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateArticleMutationResult = NonNullable<Awaited<ReturnType<typeof createArticle>>>
    export type CreateArticleMutationBody = CreateArticleInboundDto
    export type CreateArticleMutationError = BadRequestResponse

    export const useCreateArticle = <TError = BadRequestResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createArticle>>, TError,{data: CreateArticleInboundDto}, TContext>, }
) => {
    
      const mutationOptions = getCreateArticleMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const getArticle = (
    articleId: string,
 signal?: AbortSignal
) => {
      return customInstance<GetArticleOutboundDto[]>(
      {url: `/articles/${articleId}`, method: 'get', signal
    },
      );
    }
  

export const getGetArticleQueryKey = (articleId: string,) => [`/articles/${articleId}`] as const;
  

    
export const getGetArticleQueryOptions = <TData = Awaited<ReturnType<typeof getArticle>>, TError = unknown>(articleId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getArticle>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<typeof getArticle>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetArticleQueryKey(articleId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getArticle>>> = ({ signal }) => getArticle(articleId, signal);
    
      
      
   return  { queryKey, queryFn, enabled: !!(articleId), ...queryOptions}}

export type GetArticleQueryResult = NonNullable<Awaited<ReturnType<typeof getArticle>>>
export type GetArticleQueryError = unknown

export const useGetArticle = <TData = Awaited<ReturnType<typeof getArticle>>, TError = unknown>(
 articleId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getArticle>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetArticleQueryOptions(articleId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const createContacts = (
    createContactInboundDto: CreateContactInboundDto,
 ) => {
      return customInstance<void>(
      {url: `/contacts`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createContactInboundDto
    },
      );
    }
  


export const getCreateContactsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createContacts>>, TError,{data: CreateContactInboundDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createContacts>>, TError,{data: CreateContactInboundDto}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createContacts>>, {data: CreateContactInboundDto}> = (props) => {
          const {data} = props ?? {};

          return  createContacts(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateContactsMutationResult = NonNullable<Awaited<ReturnType<typeof createContacts>>>
    export type CreateContactsMutationBody = CreateContactInboundDto
    export type CreateContactsMutationError = unknown

    export const useCreateContacts = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createContacts>>, TError,{data: CreateContactInboundDto}, TContext>, }
) => {
    
      const mutationOptions = getCreateContactsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const getUsers = (
    
 signal?: AbortSignal
) => {
      return customInstance<UserOutboundDto[]>(
      {url: `/users`, method: 'get', signal
    },
      );
    }
  

export const getGetUsersQueryKey = () => [`/users`] as const;
  

    
export const getGetUsersQueryOptions = <TData = Awaited<ReturnType<typeof getUsers>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsers>>> = ({ signal }) => getUsers(signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getUsers>>>
export type GetUsersQueryError = unknown

export const useGetUsers = <TData = Awaited<ReturnType<typeof getUsers>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const getWorks = (
    
 signal?: AbortSignal
) => {
      return customInstance<GetWorksOutboundDto>(
      {url: `/works`, method: 'get', signal
    },
      );
    }
  

export const getGetWorksQueryKey = () => [`/works`] as const;
  

    
export const getGetWorksQueryOptions = <TData = Awaited<ReturnType<typeof getWorks>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorks>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<typeof getWorks>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorksQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorks>>> = ({ signal }) => getWorks(signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetWorksQueryResult = NonNullable<Awaited<ReturnType<typeof getWorks>>>
export type GetWorksQueryError = unknown

export const useGetWorks = <TData = Awaited<ReturnType<typeof getWorks>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorks>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetWorksQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


