import { Container, Divider, List, ListItem, Typography } from "@mui/material";
import { ITEMS } from "components/MagicWall/MagicWall.service";
import Page from "components/Page";
import { useParams } from "react-router-dom";

interface Props {}

const WorkItem = (props: Props) => {
  const {} = props;
  const { workId } = useParams();
  const work = ITEMS.find((item) => item.id === workId);

  if (!work) {
    return (
      <Page>
        <Container>
          <Typography>Not found</Typography>
        </Container>
      </Page>
    );
  }

  return (
    <Page>
      <Container>
        <Typography variant="h1">{work.alt}</Typography>
        <Typography variant="h2">2015 à 2017</Typography>
        <Divider />
        <Typography variant="h5">Impacts</Typography>
        <List>
          <ListItem>
            <Typography variant="body2">Constat d'étonnement</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">
              Etudes d'amélioration des méthodes de travail
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">
              Conception et réalisation du Proof of Concept de la maintenance
              préventive des calculateurs d'avions
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">Conduite du changement</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">
              Conception et réalisation du Proof of Concept de l'assistance
              vocale pour la maintenance des roues d'avions
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">
              Gain de rapidité sur la maintenance des calculateurs et des roues
              de plus de 30%
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">
              Réduction du coût des consommables pour l’entretien des roues de
              30 000€ par an
            </Typography>
          </ListItem>
        </List>
        <Typography variant="h5">Histoire</Typography>
        <Typography variant="body2">
          <p>
            J'ai toujours été passionné par les avions, depuis mon plus jeune
            âge. J'aimais passer des heures à observer les avions décoller et
            atterrir, et à étudier leur construction et leur fonctionnement.
            C'est cette passion qui m'a conduit à m'intéresser de près à
            l'entreprise aéronautique Air France, avec une fierté nationale.
          </p>
          <p>
            En rejoignant cette entreprise, j'ai rapidement découvert un
            environnement de travail qui utilisait des processus et des méthodes
            obsolètes. Mais plutôt que de me décourager, j'ai vu cette situation
            comme une opportunité d'apprendre en profondeur le domaine
            aéronautique et de chercher des solutions innovantes pour améliorer
            les processus existants.
          </p>
          <p>
            C'est ainsi que j'ai commencé à travailler sur la conception de
            solutions pour optimiser les processus de maintenance des avions.
            Après avoir étudié en profondeur les défis de la maintenance
            aéronautique, j'ai proposé une solution novatrice qui permettait de
            réduire les temps d'immobilisation des avions, tout en améliorant la
            sécurité et la qualité de la maintenance.
          </p>
          <p>
            J'ai ensuite réalisé un proof of concept de cette solution, en
            collaboration avec une équipe de développeurs et d'ingénieurs. Nous
            avons testé cette solution en situation réelle, en travaillant sur
            un avion en cours de maintenance, et avons pu constater les
            résultats positifs de notre solution.
          </p>
          <p>
            Mais le plus grand défi a été de conduire le changement et de faire
            accepter cette solution par les équipes de maintenance. J'ai
            travaillé en étroite collaboration avec les équipes de terrain pour
            les former et les accompagner dans la mise en place de cette
            nouvelle méthode de travail.
          </p>
        </Typography>
        <Typography variant="h5">Conclusion</Typography>
        <Typography variant="body2">
          <p>
            Grâce à cette expérience, j'ai acquis une expertise solide dans le
            domaine aéronautique, ainsi que des compétences en conception et en
            conduite de projets de changement. Cette expérience a été un
            véritable catalyseur pour mon développement professionnel et
            personnel.
          </p>
        </Typography>
      </Container>
    </Page>
  );
};

export default WorkItem;
