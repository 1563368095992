import { Stack, ThemeProvider } from "@mui/material";
import { QueryClientProvider } from "react-query";
import { SnackbarProvider } from "notistack";
import { IntlProvider } from "react-intl";

import Menu from "components/Menu";

import { queryClient } from "services/reactQuery";
import { muiTheme } from "services/styles.service";
import enTranslation from "translations/en.json";

import { background } from "services/theme";
import Global from "components/Global";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import ErrorPage from "components/ErrorPage/ErrorPage";
import About from "components/About";
import MagicWall from "components/MagicWall/MagicWall";
import Blog from "components/Blog/Blog";
import Contact from "components/Contact";
import BlogItemPage from "components/BlogItemPage";
import { ROUTES } from "services/routes";
import SkillPage from "components/SkillPage";
import WorkItem from "components/WorkItem";

const router = createBrowserRouter(
  [
    {
      path: ROUTES.HOME(),
      element: <Global />,
      errorElement: <ErrorPage />,
    },
    {
      path: ROUTES.SKILLS(),
      element: <SkillPage />,
    },
    {
      path: ROUTES.ABOUT(),
      element: <About />,
    },
    {
      path: ROUTES.WORKS(),
      element: (
        <Stack width="100%">
          <MagicWall />
        </Stack>
      ),
    },
    {
      path: ROUTES.WORK(),
      element: <WorkItem />,
    },
    {
      path: ROUTES.BLOG(),
      element: <Blog />,
    },
    {
      path: ROUTES.ARTICLE(),
      element: <BlogItemPage />,
    },
    {
      path: ROUTES.CONTACT(),
      element: <Contact />,
    },
  ],
  {
    basename: "/",
  }
);

const App = () => {
  return (
    <IntlProvider messages={enTranslation} locale="en" defaultLocale="en">
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={muiTheme}>
          <SnackbarProvider>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              sx={{ background: background.tertiary }}
            >
              <Menu />
              <RouterProvider router={router} />
            </Stack>
          </SnackbarProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </IntlProvider>
  );
};

export default App;
