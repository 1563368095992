import { Box, Button } from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./Menu.module.scss";
import ResponsiveMenu from "components/ResponsiveMenu";
import MenuIcon from "@mui/icons-material/Menu";
interface Props {}

const Menu = (props: Props) => {
  const {} = props;

  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const onClickTogglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  return (
    <div className={styles.view}>
      <Box sx={{ display: { xs: "none", md: "block" }, width: 130 }}>
        <ResponsiveMenu />
      </Box>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        {isPanelOpen && <ResponsiveMenu />}
        <Button className={styles.button} onClick={onClickTogglePanel}>
          {isPanelOpen ? <CloseIcon /> : <MenuIcon />}
        </Button>
      </Box>
    </div>
  );
};

export default Menu;
